import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import * as actionCreators from '../actions/actionCreators';
import ProfileGroupTable from '../components/ProfileGroupTable';

import { setProfileGroup } from '../ducks/ui';

import {  getSelectedProfileGroup,
          getActiveProfiles,
          getProfilesCount,
          getActiveProfilesCount,
          getDormantProfilesCount,
          getDormantProfiles,
          getOnlineProfiles, 
          getOnlineProfilesCount,
          getOfflineProfilesCount, 
          getHealthyProfiles,
          getHealthyProfilesCount,
          getDegradedProfiles,
          getDegradedProfilesCount,
          getSevereDegradedProfiles,
          getSevereDegradedProfilesCount,  
          getCriticalProfiles,
          getCriticalProfilesCount,
          getSevereCriticalProfiles,
          getSevereCriticalProfilesCount } from '../selectors/profiles';

function ProfileGroup(props) {
  const dispatch = useDispatch();
  // const [selectedProfileGroup, setSelectedProfileGroup] = useState(null);
  let { profile_group_id } = useParams();

  const fetchProfileGroups = props.fetchProfileGroups;
  useEffect(() => {    
    fetchProfileGroups();
  }, [fetchProfileGroups]);

  useEffect(() => {    
    dispatch(setProfileGroup(profile_group_id));
  });

  return <ProfileGroupTable 
                            profileGroup={props.profileGroup}
                            activeProfiles={props.activeProfiles}
                            dormantProfiles={props.dormantProfiles}
                            profilesCount={props.profilesCount}
                            activeProfilesCount={props.activeProfilesCount}
                            dormantProfilesCount={props.dormantProfilesCount}
                            onlineProfiles={props.onlineProfiles}
                            onlineProfilesCount={props.onlineProfilesCount}
                            offlineProfilesCount={props.offlineProfilesCount}
                            healthyProfiles={props.healthyProfiles}
                            healthyProfilesCount={props.healthyProfilesCount}
                            degradedProfiles={props.degradedProfiles}
                            degradedProfilesCount={props.degradedProfilesCount}
                            severeDegradedProfiles={props.severeDegradedProfiles}
                            severeDegradedProfilesCount={props.severeDegradedProfilesCount}
                            criticalProfiles={props.criticalProfiles}
                            criticalProfilesCount={props.criticalProfilesCount}
                            severeCriticalProfiles={props.severeCriticalProfiles}
                            severeCriticalProfilesCount={props.severeCriticalProfilesCount}
                            generateEntityReport={props.generateEntityReport} />;

}


const mapStateToProps = (state, ownProps) => {
  return {
    activeProfiles: getActiveProfiles(state),
    dormantProfiles: getDormantProfiles(state),
    dormantProfilesCount: getDormantProfilesCount(state),
    profilesCount: getProfilesCount(state),
    activeProfilesCount: getActiveProfilesCount(state),
    onlineProfiles: getOnlineProfiles(state),
    onlineProfilesCount: getOnlineProfilesCount(state),
    offlineProfilesCount: getOfflineProfilesCount(state),
    healthyProfiles: getHealthyProfiles(state),
    healthyProfilesCount: getHealthyProfilesCount(state),
    degradedProfiles: getDegradedProfiles(state),
    degradedProfilesCount: getDegradedProfilesCount(state),
    severeDegradedProfiles: getSevereDegradedProfiles(state),
    severeDegradedProfilesCount: getSevereDegradedProfilesCount(state),
    criticalProfiles: getCriticalProfiles(state),
    criticalProfilesCount: getCriticalProfilesCount(state),
    severeCriticalProfiles: getSevereCriticalProfiles(state),
    severeCriticalProfilesCount: getSevereCriticalProfilesCount(state),
    profileGroup: getSelectedProfileGroup(state),
  };
};


export default connect(mapStateToProps, actionCreators)(ProfileGroup);