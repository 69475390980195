import React, { } from "react";
// import { makeStyles } from '@material-ui/core/styles';

import { Link } from 'react-router-dom';

// import CircularProgress from '@material-ui/core/CircularProgress';

import isValid from 'date-fns/isValid';
import parseISO from 'date-fns/parseISO';

import formatDistanceToNow from 'date-fns/formatDistanceToNow';
// import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import StarStatus from './StarStatus';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import Button from '@material-ui/core/Button';


// const useStyles = makeStyles(theme => ({
//   root: {
//     flexGrow: 1,
//     backgroundColor: "whitesmoke",
//   },
//   mainContainer: {
//     margin: 50,
//     display: "flex",
//     flexDirection: "column",
//     justifyContent: "center",
//     alignItems: "stretch"
//   },
//   schemas: {
//     width: "70%",
//   },
//   schemaTable: {
//     marginTop: 50
//   }
// }));

export default function ProfileGroupTable(props) {
  
  // const classes = useStyles();

  const timeSince = (_time) => {
     let pastTime = parseISO(_time);
      if(!isValid(pastTime)){
        return "Invalid Date";
      } else {
        return formatDistanceToNow(pastTime);
      }
  }

  const renderBooleanIcon = (check) => {
    return check ? <CheckCircleIcon style={{color: "green"}} /> : <ErrorIcon color="error" /> ;
  }

  const renderPresenceStatus = (online, last_presence_change_at) => {
    if(online === null){
      return "Never seen";
    }else if(last_presence_change_at !== null){
      return <span>{ renderBooleanIcon(online) } for {timeSince(last_presence_change_at)}</span>;
    } else {
      return "Unknown";
    }
  }

  const handleSendReport = () => {
    // Hardcoded to Pendant and button for now
    props.generateEntityReport(props.profileGroup?.id, ['64cc7188908a5f15cbf3efb7', '64cc83e4908a5f16c4263893']);
  }

  

  const renderProfiles = (profiles) => {
    return <Paper className="tableRoot">
      <Table className="table">
        <TableHead>
          <TableRow>
            <TableCell style={{ width: "60%" }}>Name</TableCell>
            <TableCell align="right">Registered Entities</TableCell>
            <TableCell align="left">Online?</TableCell>
            <TableCell align="right">Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {profiles.map(profile => (
            <TableRow key={profile.name}>
              <TableCell component="th" scope="row"><Link to={`/profiles/${profile.id}`}>{profile.name}</Link></TableCell>
              <TableCell align="right">{profile.entity_count}</TableCell>
              <TableCell align="left" style={{ verticalAlign: 'center' } } >{renderPresenceStatus(profile.online, profile.last_presence_change_at)}</TableCell>
              <TableCell align="right">
                <StarStatus status={profile.status} size='large' />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>;
  }


  return (
    <div  className="indexContainer" >
      <h1>{props.profileGroup?.name}</h1>
      <Button onClick={handleSendReport} color="primary">
            Send Report
      </Button>
    <Grid container>
      <Grid item xs={4} sm={2} md={2} lg={2}>
        <Paper className="paper" >
          <Typography color="primary" className="countHeading" variant="h4">Total</Typography>
          <Typography color="primary" className="heading" variant="h3">{props.profilesCount}</Typography>
        </Paper>
      </Grid>
      <Grid item xs={4} sm={2} md={2} lg={2}>
        <Paper className="paper" >
          <Typography color="primary" className="countHeading" variant="h4">Active</Typography>
          <Typography color="primary" className="heading" variant="h3">{props.activeProfilesCount}</Typography>
        </Paper>
      </Grid>
      <Grid item xs={4} sm={2} md={2} lg={2}>
        <Paper className="paper" >
          <Typography color="primary" className="countHeading" variant="h4">Dormant</Typography>
          <Typography color="primary" className="heading" variant="h3">{props.dormantProfilesCount}</Typography>
        </Paper>
      </Grid>
      <Grid item xs={4} sm={2} md={2} lg={2}>
        <Paper  className="paper">
          <Typography className="countHeading" variant="h4">Online</Typography>
          <Typography className="heading" variant="h3">{props.onlineProfilesCount}</Typography>
        </Paper>
      </Grid>
      <Grid item xs={4} sm={2} md={2} lg={2}>
        <Paper className="paper">
          <Typography className="countHeading" variant="h4">Offline</Typography>
          <Typography className="heading" variant="h3">{props.offlineProfilesCount}</Typography>
        </Paper>
      </Grid>
    </Grid>
    
    <Grid container>
      <Grid item xs={4} sm={2} md={2} lg={2}>
        <Paper className="paper">
          <Typography className="countHeading" variant="h4">Healthy</Typography>
          <Typography className="heading" variant="h3">{props.healthyProfilesCount}</Typography>
        </Paper>
      </Grid>
      <Grid item xs={4} sm={2} md={2} lg={2}>
        <Paper className="paper">
          <Typography className="countHeading" variant="h4">Degraded</Typography>
          <Typography className="heading" variant="h3">{props.degradedProfilesCount}</Typography>
        </Paper>
      </Grid>
      <Grid item xs={4} sm={2} md={2} lg={2}>
        <Paper className="paper">
          <Typography className="countHeading" variant="h4">Severe Degraded</Typography>
          <Typography className="heading" variant="h3">{props.severeDegradedProfilesCount}</Typography>
        </Paper>
      </Grid>
      <Grid item xs={4} sm={2} md={2} lg={2}>
        <Paper className="paper">
          <Typography className="countHeading" variant="h4">Critical</Typography>
          <Typography className="heading" variant="h3">{props.criticalProfilesCount}</Typography>
        </Paper>
      </Grid>
      <Grid item xs={4} sm={2} md={2} lg={2}>
        <Paper className="paper">
          <Typography className="countHeading" variant="h4">Severe Critical</Typography>
          <Typography className="heading" variant="h3">{props.severeCriticalProfilesCount}</Typography>
        </Paper>
      </Grid>
    </Grid>
    
    <h2>Active Profiles</h2>
    <h3>Severe Critical</h3>
    {renderProfiles(props.severeCriticalProfiles)}
    <br></br>
    <h3>Critical</h3>
    {renderProfiles(props.criticalProfiles)}
    <br></br>
    <h3>Severe Degraded</h3>
    {renderProfiles(props.severeDegradedProfiles)}
    <br></br>
    <h3>Degraded</h3>
    {renderProfiles(props.degradedProfiles)}
    <br></br>
    <h3>Healthy</h3>
    {renderProfiles(props.healthyProfiles)}
    <br></br>
    <h2>Dormant Profiles</h2>
    {renderProfiles(props.dormantProfiles)}
  </div>
  );
}